import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null); // Store profile data
  const [loading, setLoading] = useState(true); // Store loading state
  const navigate = useNavigate();  // Initialize navigate

  // Function to fetch and update the profile from the backend
  const fetchProfile = async () => {
    
    setLoading(true); // Start loading
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';
      const response = await axios.get(`${backendUrl}/profile`, { withCredentials: true });
      setProfile(response.data); // Update profile data
    } catch (error) {
      navigate('/login'); 
      console.error('Error fetching profile: User not logged in');

    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to update the profile locally (without contacting the backend)
  const updateProfileLocally = (updatedData) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      ...updatedData,
    }));
  };

  
  function fetchFrontendProfile() {
    return profile;
  }

  useEffect(() => {
    if (!profile) {
      fetchProfile(); // Fetch profile only if it's not already set
    }
  }, [profile]);

  return (
    <ProfileContext.Provider value={{ profile, setProfile, fetchProfile, updateProfileLocally,fetchFrontendProfile, loading }}>
      {children}
    </ProfileContext.Provider>
  );
};
