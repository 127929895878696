import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import logo from './logo192.png';
import { ThreeDots } from 'react-loader-spinner';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);  // Start the loading spinner
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';
      const response = await axios.post(`${backendUrl}/forgot-password`, { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Password reset failed');
    } finally {
      setLoading(false);  // Stop the loading spinner
    }
  };


  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <img src={logo} alt="CUNY Course Sniper Logo" style={styles.logo} /> {/* Logo added here */}
        {/* <h2 style={styles.title}>CUNY Course Sniper</h2> */}
        <h3 style={styles.subtitle}>Reset Your Password</h3>
        <form onSubmit={handleForgotPassword}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>

          {loading && (
            <div style={styles.loaderContainer}>
              <ThreeDots color="#007bff" height={40} width={40} />
            </div>
          )}
        </form>
        <p 
          className="link" 
          style={{ 
            textAlign: 'center', 
            marginTop: '20px' // Add some margin if needed 
          }}
        >
          <Link to="/login">Remember your password? Login</Link>
        </p>
        {message && <p style={message === 'Password reset failed' ? styles.errorMessage : styles.successMessage}>{message}</p>}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fff',
  },
  box: {
    maxWidth: '400px',
    width: '100%',
    padding: '35px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '225px', // Increased size from 100px to 150px
    marginBottom: '5px',
  },
  // title: {
  //   textAlign: 'center',
  //   color: '#007bff',
  // },
  subtitle: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    textAlign: 'center !important',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
  errorMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'green',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
};

export default ForgotPassword;