import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PendingTransactions.css'; // Import CSS for styling
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { FaUndo } from 'react-icons/fa';




const PendingTransactions = () => {
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';  // Use environment variable or default to localhost
  const [selectedClass, setSelectedClass] = useState(null);
  const [loadingClass, setLoadingClass] = useState(null); // Track which class is being processed
  const [classesToDrop, setClassesToDrop] = useState([]);

  useEffect(() => {
    // Fetch pending transactions on component load
    const fetchTransactions = async () => {
      
      try {
        const response = await axios.get(`${backendUrl}/fetch-pending-transactions`, { withCredentials: true });
        console.log(response.data);
        setPendingTransactions(response.data || []);
        console.log(pendingTransactions, 'pendingTransactions11111');
      } catch (error) {
        // console.error('Error fetching transactions:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login', { replace: true });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  if (loading) {
    return (
      <div className="transactions-loading-indicator">
        <ThreeDots color="#385170" height={40} width={40} />
        <p className="loading-message">Fetching pending transactions...</p>
      </div>
    );
  }

  
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page in history
  };

  const handleClickProfile = (e) => {
    e.preventDefault();
    navigate('/profile');
  };
  
  const handleClickSchedule = (e) => {
    e.preventDefault();
    navigate('/mySchedule');
  };

  const handleClicktracking = (e) => {
    e.preventDefault();
    navigate('/searchPage/Baruch-College/Spring-2025-Term'); // need to change depending on term we're in
  };
  const handleLogout = async () => {
    await axios.post(`${backendUrl}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };


  const handleClassClick = (classDetails) => {
    setSelectedClass(classDetails);
  };

  const closeModal = () => {
    setSelectedClass(null); // Close the modal
  };


  const parseDaysTimes = (daysTimes) => {
    const dayMapping = {
      'Mo': 'Monday',
      'Tu': 'Tuesday',
      'We': 'Wednesday',
      'Th': 'Thursday',
      'Fr': 'Friday',
      'Sa': 'Saturday',
      'Su': 'Sunday',
    };
  
    // Regex to match time and ensure space between time and AM/PM
    const regex = /([A-Za-z]{2})([A-Za-z]{2})?\s(\d{1,2}:\d{2})([APM]+)-(\d{1,2}:\d{2})([APM]+)/g;
    let match;
    const result = [];
  
    while ((match = regex.exec(daysTimes)) !== null) {
      const day1 = dayMapping[match[1]];  // Map first day abbreviation
      const day2 = match[2] ? dayMapping[match[2]] : null; // Map second day abbreviation if present
      let startTime = `${match[3]} ${match[4]}`;  // Add space between time and AM/PM
      let endTime = `${match[5]} ${match[6]}`;    // Add space between time and AM/PM
  
      result.push({ day: day1, start_time: startTime, end_time: endTime });
      if (day2) {
        result.push({ day: day2, start_time: startTime, end_time: endTime });
      }
    }
    return result;
  };

  const formatToLocalTime = (gmtTimestamp) => {
    const date = new Date(gmtTimestamp);
  
    // Format options to display the local time nicely
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,  // Use 12-hour format
    };
  
    return date.toLocaleString(undefined, options); // 'undefined' defaults to user's locale
  };


  const handleEditTransaction = (transaction) => {
    // console.log('View transaction info:', transaction);
    navigate('/mySchedule', {
        state: {
            classToAdd: transaction, // Pass the class as classToAdd
            selectedCollege: transaction.college_name, 
            selectedTerm: transaction.term_name, 
            predeterminedClassesToDrop: transaction.dropped_classes_details.map((classDetails) => classDetails.class_id),
            thisanedit: true,
        },
    });
  };
  const submitDeleteTransaction = async () => {
    //Submit the list of classes to drop
    console.log("Got here");
    setLoading(true);
    try {
      const response = await axios.post(`${backendUrl}/delete-pending-transactions`, { classes: classesToDrop }, { withCredentials: true });
      // console.log(response.data);
      setClassesToDrop((prevTransactions) => prevTransactions.filter((transaction) => !classesToDrop.includes(transaction.class_number)));
      //reload the page to show the updated transactions
      window.location.reload();
    } catch (error) {
      console.error('Error deleting classes:', error);
    } finally {
      setLoading(false);
      setClassesToDrop([]); // Clear the list of classes to drop
    }
  }
  


  return (
    <div className="pending-trans-container">
      {/* Top navigation buttons */}
      <div className="pending-trans-nav">
        <button onClick={handleGoBack} className="pending-trans-go-back-button">Go Back</button>
  
        {/* Dropdown Button with "My Account" */}
        <div className="pending-trans-accountDropdownContainer">
          <button className="pending-trans-navButtonRight" onClick={() => setShowDropdown(!showDropdown)}>
            My Account
          </button>
          {showDropdown && (
            <div className="pending-trans-dropdown">
              <button className="pending-trans-dropdownButton" onClick={handleClickSchedule}>
                Schedule
              </button>
              <button className="pending-trans-dropdownButton" onClick={handleClickProfile}>
                Profile
              </button>
              <button className="pending-trans-dropdownButton" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
  
      <h1>Pending Transactions</h1>
      {pendingTransactions.length === 0 ? (
        <p className="no-transactions-message">No pending transactions found.</p>
      ) : (
        
   
        <div className="transactions-table-container">
          <div className="transaction-actions-button-container">
            <button className="trans-submit-drops-button" 
             onClick={(e) => {
              e.stopPropagation(); // Prevent propagation, just in case
              submitDeleteTransaction();
            }}
            >
              Submit Adjustments
            </button>
            < button className="trans-view-all-tracked-button" 
              onClick= {(e) => {
                e.stopPropagation();
                handleClicktracking(e);
              }}
            >
              View All Tracked Classes
            </button>
          </div>
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Class Code</th>
                <th>Class Number</th>
                <th>Priority (Within Tier)</th>
                <th>Priority (Overall)</th>
                <th>Class(es) to Drop</th>
                <th> Transaction Time </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {pendingTransactions.map((transaction) => (
              <tr 
                key={transaction.class_id} 
                className={transaction.is_flagged ? 'flagged-transaction' : ''} 
                title={transaction.is_flagged ? transaction.flagged_message : ''}
              >
                <td>{transaction.class_code}</td>
                <td>
                  <span 
                    className="pending-trans-class-link"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleClassClick(transaction)}
                    onKeyDown={(e) => e.key === 'Enter' && handleClassClick(transaction)}
                  >
                    {transaction.class_number}
                  </span>
                </td>
                <td>{transaction.rank_within_tier} ({transaction.membership_tier})</td>
                <td>{transaction.overall_rank}</td>
                <td>
                  {transaction.dropped_classes_details.map((droppedClass, index) => (
                    <div key={index}>
                      <span 
                        className="pending-trans-class-link"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleClassClick(droppedClass)}
                        onKeyDown={(e) => e.key === 'Enter' && handleClassClick(droppedClass)}
                      >
                        {droppedClass.class_number}
                      </span>
                    </div>
                  ))}
                </td>
                <td>{formatToLocalTime(transaction.updated_last)}</td>
                <td>
                <div className="transaction-actions">
                  <button
                    className="transaction-removeButton"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (classesToDrop.includes(transaction.class_id)) {
                        // Unmark for deletion
                        setClassesToDrop(classesToDrop.filter(id => id !== transaction.class_id));
                        // console.log(classesToDrop, 'classesToDrop');
                      } else {
                        // Mark for deletion
                        setClassesToDrop([...classesToDrop, transaction.class_id]);
                        // console.log(classesToDrop, 'classesToDrop');
                      }
                    }}
                    disabled={loadingClass === transaction.class_number}
                >
                    {loadingClass === transaction.class_number ? (
                        <ThreeDots
                            height="10"
                            width="24"
                            radius="9"
                            color="#007bff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                      ) : classesToDrop.includes(transaction.class_id) ? (
                        <FaUndo />
                      ) : (
                        <FaTrash />
                      )}
                  </button>
                  <button
                    className="transaction-editButton"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEditTransaction(transaction);
                    }}
                    disabled={loadingClass === transaction.class_number}
                >
                    {loadingClass === transaction.class_number ? (
                        <ThreeDots
                            height="10"
                            width="24"
                            radius="9"
                            color="#007bff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    ) : (
                        'Edit'
                    )}
                  </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          </table>
        </div>
      )}

      {/* Modal for showing detailed class info - Stole css from the schedule page*/}
      {selectedClass && (
        <div className="schedule-modal">
          <div className="schedule-modal-content">
            <h2>{selectedClass.class_code} - {selectedClass.course_topic}</h2>
            <p><strong>Instructor:</strong> {selectedClass.instructor}</p>
            <p><strong>Class Number:</strong> {selectedClass.class_number}</p>
            <p><strong>Enrollment Status:</strong> {selectedClass.status}</p>
            <p><strong>Location:</strong> {selectedClass.room}</p>
            <p><strong>Days and Times:</strong></p>
            {parseDaysTimes(selectedClass.days_times).map(({ day, start_time, end_time }, index) => (
              <p key={index}>
                {day}: {start_time} - {end_time}
              </p>
            ))}
            <p><strong>Meeting Dates:</strong> {selectedClass.meeting_dates}</p>
            <p><strong>Instruction Mode:</strong> {selectedClass.instruction_mode}</p>
            <p><strong>Credits:</strong> {selectedClass.class_credits}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
      {/* Edit Transaction Modal */}
      </div>
  );
};

export default PendingTransactions;