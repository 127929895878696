export const collegeCreditInfo = {
    'Baruch College': {
      maxCreditsWithoutOverload: 18,
      maxCreditsWithOverload: 21,
      winterMaxCreditsWithoutOverload: 7, // 2 classes converted to credits
      winterMaxCreditsWithOverload: 10.5, // 3 classes converted to credits
      summerMaxCreditsWithoutOverload: 16, // 4 classes; 2 in each session converted to credits
      summerMaxCreditsWithOverload: 19 // 5 total classes; max of 3 in one session
    },
      'Borough of Manhattan CC': {
      maxCreditsWithoutOverload: 18,
      maxCreditsWithOverload: null, // No specific info, user must enter
      winterMaxCreditsWithoutOverload: 4,
      winterMaxCreditsWithOverload: null, // No specific info, user must enter
      summerMaxCreditsWithoutOverload: 16, // 4 classes; 2 in each session converted to credits
      summerMaxCreditsWithOverload: null // No specific info, user must enter
    },
    'Bronx CC': {
      maxCreditsWithoutOverload: 15,
      maxCreditsWithOverload: null, // User must enter
      winterMaxCreditsWithoutOverload: null, // User must enter
      winterMaxCreditsWithOverload: null, // User must enter
      summerMaxCreditsWithoutOverload: null, // User must enter
      summerMaxCreditsWithOverload: null // User must enter
    },
    'Brooklyn College': {
      maxCreditsWithoutOverload: 18,
      maxCreditsWithOverload: null, // User must enter
      winterMaxCreditsWithoutOverload: 7,
      winterMaxCreditsWithOverload: null, // User must enter
      summerMaxCreditsWithoutOverload: 18, // 9 credits in each session/ 18 credits total
      summerMaxCreditsWithOverload: null // User must enter
    },
    'City College': {
      maxCreditsWithoutOverload: 18,
      maxCreditsWithOverload: null, // User must enter
      winterMaxCreditsWithoutOverload: 9,
      winterMaxCreditsWithOverload: null, // User must enter
      summerMaxCreditsWithoutOverload: 12,// 6 credits in each session/ 12 credits total
      summerMaxCreditsWithOverload: null // User must enter
    },
    'College of Staten Island': {
      maxCreditsWithoutOverload: 18, // check excel for the details based on gpa
      maxCreditsWithOverload: null, // User must enter
      winterMaxCreditsWithoutOverload: 4,
      winterMaxCreditsWithOverload: 7, // User must enter
      summerMaxCreditsWithoutOverload: 16, // 6 credits in each session/ 12 credits total
      summerMaxCreditsWithOverload: null // User must enter
    },
    'Graduate Center': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Guttman CC': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: 24, // Or User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Hostos CC': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Hunter College': {
        maxCreditsWithoutOverload: 17.5,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: 4,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 15, // 7.5 credits in each session/ 15 credits total
        summerMaxCreditsWithOverload: null // User must enter
    },
    'John Jay College': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: 21, // User must enter
        winterMaxCreditsWithoutOverload: 3,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 14, // 7 credits in each session/ 14 credits total
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Kingsborough CC': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: 21, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 8, // One 6 week session 8 credits
        summerMaxCreditsWithOverload: null // User must enter
    },
    'LaGuardia CC': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: 8, // 2 classes converted to credits
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 8, // One 6 week session 8 credits
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Lehman College': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: 24, // Let user enter
        winterMaxCreditsWithoutOverload: 6,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 8, // One 6 week session 8 credits
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Macaulay Honors College': {
        maxCreditsWithoutOverload: null, // User must enter
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Medgar Evers College': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: 21, // Let user enter
        winterMaxCreditsWithoutOverload: 8,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'NYC College of Technology': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Queens College': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // Let user enter
        winterMaxCreditsWithoutOverload: 4,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 15, //6 Semester I and 9 Senester II
        summerMaxCreditsWithOverload: null // User must enter
    },
    'Queensborough CC': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: 4,
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 16, // 8 credits in each session/ 16 credits total
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Journalism': {
        maxCreditsWithoutOverload: 17,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Labor & Urban Studies': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: 4, // 1 class converted to credits
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Law': {
        maxCreditsWithoutOverload: 16,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Law': {
        maxCreditsWithoutOverload: 16,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 12, // 6 per session
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Medicine': {
        maxCreditsWithoutOverload: null,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 12, // 6 per session
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Professional Studies': {
        maxCreditsWithoutOverload: null,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: null, // User must enter
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: null, // User must enter
        summerMaxCreditsWithOverload: null // User must enter
    },
    'School of Public Health': {
        maxCreditsWithoutOverload: 16,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: 4, // 1 class
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 10, // 1 single term 10 credits
        summerMaxCreditsWithOverload: null // User must enter
    },
    'York College': {
        maxCreditsWithoutOverload: 18,
        maxCreditsWithOverload: null, // User must enter
        winterMaxCreditsWithoutOverload: 4, // 1 class
        winterMaxCreditsWithOverload: null, // User must enter
        summerMaxCreditsWithoutOverload: 18, // Session I: 7; Session II: 7; Session III: 4
        summerMaxCreditsWithOverload: null // User
    },
  };
  
