import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const terms = [
  { name: 'Fall 2024', slug: '2024-Fall-Term' }, //modify to have the current term at all times
//   { name: 'Spring 2024', slug: 'spring-2024' },
  { name: 'Summer 2024', slug: '2024-Summer-Term' },
  // Add more terms as needed
];

function TermSelectionPage() {
  const navigate = useNavigate();
  const { collegeSlug } = useParams();

  const handleTermClick = (termSlug) => {
    navigate(`/searchPage/${collegeSlug}/${termSlug}`);
  };

  return (
    <div style={styles.container}>
      <h2>Select a Term</h2>
      <ul style={styles.termList}>
        {terms.map(term => (
          <li key={term.slug} style={styles.termItem}>
            <button
              style={styles.termButton}
              onClick={() => handleTermClick(term.slug)}
            >
              {term.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TermSelectionPage;

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  termList: {
    listStyleType: 'none',
    padding: 0,
  },
  termItem: {
    marginBottom: '10px',
  },
  termButton: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
};
