import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo192.png';
import { ThreeDots } from 'react-loader-spinner';


function Signup() {
  const [personalEmail, setPersonalEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // 
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate hook


  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true); // Start the loading spinner
  
    // Password strength validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setMessage('Failed: Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number');
      setLoading(false); // Stop the loading spinner
      return;
    }
    
    // Confirm password validation
    if (password !== confirmPassword) {
      setMessage('Failed: Password and Confirm Password do not match');
      setLoading(false); // Stop the loading spinner
      return;
    }
  
    const data = {
      personal_email: personalEmail,
      phone_number: phoneNumber,
      password: password,
    };
  
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';
      const response = await axios.post(`${backendUrl}/signup`, data);
      setMessage(response.data.message);
      if (response.data.message === 'Verification code sent to your email') {
        navigate('/verify-email', { state: { email: personalEmail } }); // Pass the email to the verification page
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message); // Set the message to the one returned by the signup function
      } else {
        setMessage('Sign up failed');
      }
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  
    return (
    <div style={styles.container}>
      <div style={styles.box}>
        <img src={logo} alt="CUNY Course Sniper Logo" style={styles.logo} /> {/* Logo added here */}
        {/* <h2 style={styles.title}>CUNY Course Sniper</h2> */}
        <h3 style={styles.subtitle}>Create An Account</h3>
        <form onSubmit={handleSignup}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Personal Email:</label>
            <input
              type="email"
              value={personalEmail}
              onChange={(e) => setPersonalEmail(e.target.value)}
              required
              style={styles.input}
            />
            <p style={styles.note}>
              *Please use your personal email address, as CUNY might block our emails.*
            </p>
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Phone Number:</label>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              pattern="\d{10}" // Regex pattern for 10 digits
              maxLength="10" // Limit input to 10 characters
              style={styles.input}
              title="Please enter a 10-digit phone number" // Tooltip when the pattern is violated
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Password:</label>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Confirm Password:</label>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                style={styles.checkbox}
              />
              Show Password
            </label>
          </div>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? "Creating Account..." : "Create Account"}
          </button>
          {loading && (
            <div style={styles.loaderContainer}>
              <ThreeDots color="#007bff" height={40} width={40} />
            </div>
          )}
        </form>
        <p
        className="link" 
          style={{ 
            textAlign: 'center', 
            marginTop: '10px' // Add some margin if needed 
          }}
        >
          <Link to="/login">Already have an account? Login</Link>
        </p>
        {message && <p style={!message.includes('Verification code sent to your email') ? styles.errorMessage : styles.successMessage}>{message}</p>}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fff',
  },
  box: {
    maxWidth: '400px',
    width: '100%',
    padding: '35px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    maxHeight: 'calc(100vh - 40px)', // Adjust max height
    overflowY: 'auto', // Enable scrolling if content exceeds the max height
  },
  formContainer: {
    maxHeight: 'calc(100vh - 150px)', // Calculate max height based on viewport height and other content
    overflowY: 'auto', // Enable vertical scrolling if the form content exceeds the max height
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '275px', // Increase the size of the logo
    marginBottom: '5px', // Add more space below the logo to lower it
  },
  // title: {
  //   textAlign: 'center',
  //   color: '#007bff',
  // },
  subtitle: {
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '20px', // Adjusted the margin for better spacing
    fontSize: '24px',  // Increased font size
    fontWeight: 'bold', // Made the subtitle bold
    color: '#333',  // Set a dark grey color for a more subtle look
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  note: {
    color: '#555', // Softer dark grey color
    fontSize: '14px', // Slightly smaller font size
    fontStyle: 'italic', // Italicized text
    marginTop: '0px', // Small margin at the top
    marginBottom: '0px', // Space below the note
    textAlign: 'center'
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  checkbox: {
    marginBottom: '4px',
    marginRight: '8px',
    transform: 'scale(1.5)',  // Optional: Increase the size of the checkbox for better alignment
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#142d4c',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
  errorMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'green',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  
};

export default Signup;
