import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import './homepage.css'; // Correct the path here
import { ProfileContext } from './ProfileContext'; // Import ProfileContext
import logo from './logo192.png';

// Example colleges data with student count and theme colors
const collegesData = [
  { name: 'Baruch College', students: 18000, color: '#0033A0' },
  { name: 'Borough of Manhattan CC', students: 12000, color: '#1D3E70' },
  { name: 'Bronx CC', students: 10000, color: '#0092CA' },
  { name: 'Brooklyn College', students: 17000, color: '#7B1113' },
  { name: 'City College', students: 16000, color: '#632A53' },
  { name: 'College of Staten Island', students: 14000, color: '#003366' },
  { name: 'Graduate Center', students: 5050, color: '#003A63' },
  { name: 'Guttman CC', students: 3000, color: '#009682' },
  { name: 'Hostos CC', students: 6000, color: '#ED7000' },
  { name: 'Hunter College', students: 23000, color: '#4B2E83' },
  { name: 'John Jay College', students: 15050, color: '#0057B8' },
  { name: 'Kingsborough CC', students: 13000, color: '#00356B' },
  { name: 'LaGuardia CC', students: 19000, color: '#D51A1F' },
  { name: 'Lehman College', students: 11000, color: '#006747' },
  { name: 'Macaulay Honors College', students: 2000, color: '#A7A9AC' },
  { name: 'Medgar Evers College', students: 7000, color: '#000000' },
  { name: 'NYC College of Technology', students: 21000, color: '#0077C8' },
  { name: 'Queens College', students: 20000, color: '#B71234' },
  { name: 'Queensborough CC', students: 17000, color: '#E9B749' },
  { name: 'School of Journalism', students: 1000, color: '#4D4F53' },
  { name: 'School of Labor & Urban Studies', students: 2000, color: '#4D4F53' },
  { name: 'School of Law', students: 800, color: '#005BAC' },
  { name: 'School of Medicine', students: 500, color: '#5B6770' },
  { name: 'School of Professional Studies', students: 9000, color: '#E87722' },
  { name: 'School of Public Health', students: 4000, color: '#008066' },
  { name: 'York College', students: 8000, color: '#B31B1B' },
];


const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth(); // January is 0, December is 11

// If it's after August, Winter and Spring belong to the next year
const nextYear = currentMonth >= 8 ? currentYear + 1 : currentYear;
const terms = [
  // { name: `Summer ${currentYear}`, slug: `${currentYear}-Summer-Term` },
  { name: `Fall ${currentYear}`, slug: `${currentYear}-Fall-Term` },
  { name: `Winter ${nextYear}`, slug: `${nextYear}-Winter-Term` },
  { name: `Spring ${nextYear}`, slug: `${nextYear}-Spring-Term` },
  
  // Add more terms or dynamically calculate others if needed
];
const SearchInput = ({ searchTerm, setSearchTerm }) => (
  <div className="homepage-inputGroup">
    <input
      type="text"
      placeholder="Find your college"
      className="homepage-input"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>
);

const CollegeButton = ({ name, color }) => {
  const navigate = useNavigate();
  const collegeSlug = name.replace(/\s+/g, '-');

  const handleTermClick = (termSlug) => {
    navigate(`/searchPage/${collegeSlug}/${termSlug}`);
  };

  return (
    <div className="homepage-collegeButtonContainer">
      <button 
        className="homepage-collegeButton"
        style={{ backgroundColor: color }}
      >
        {name}
        <div className="homepage-termDropdown">
          {terms.map(term => (
            <button 
              key={term.slug}
              className="homepage-termButton"
              onClick={() => handleTermClick(term.slug)}
            >
              {term.name}
            </button>
          ))}
        </div>
        </button>
      </div>
  );
};

const CollegeGrid = ({ colleges }) => (
  <div className="homepage-buttonGrid">
    {colleges.slice(0, 20).map((college, index) => (
      <CollegeButton key={index} name={college.name} color={college.color} />
    ))}
  </div>
);

const Home = () => {
  const { profile, loading } = useContext(ProfileContext); // Access profile data from context
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [enrolledCollege, setEnrolledCollege] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const [sortedColleges, setSortedColleges] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';

  const confirmLoggedIn = async () => {
    try {
      // Send a request to check if the user is authenticated
      const response = await axios.get(`${backendUrl}/check-login`, {
        withCredentials: true,
      });
  
      // console.log('User is logged in:', response.data); // Log if the user is authenticated
      return true;  // Return true if the user is logged in
    } catch (error) {
      console.error('User is not logged in:', error); // Log error details
      if (error?.response?.status === 401) {
        console.log('Redirecting to login page');
        navigate('/login', { replace: true }); // Redirect to login if not authenticated
      }
      return false; // Return false if not logged in
    }
  };
  
  useEffect(() => {
 
  
    confirmLoggedIn();
    // Access specific parts of the profile, e.g., profile.college
    if (profile) {
      console.log(profile.college);
      const enrolledCollege = profile.college;
      setEnrolledCollege(enrolledCollege);

      const filteredColleges = collegesData
        .filter(college => 
          college.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
          college.name !== enrolledCollege
        )
        .sort((a, b) => b.students - a.students);

      setSortedColleges(filteredColleges);
    }
  }, [profile, searchTerm]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.homepage-accountDropdownContainer')) {
        setShowDropdown(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickProfile = (e) => {
    e.preventDefault();
    navigate('/profile');
  };

  const handleClickSchedule = (e) => {
    e.preventDefault();
    navigate('/mySchedule');
  };

  const handleClickPendingTransactions = (e) => {
    e.preventDefault();
    navigate('/PendingTransactions');
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${backendUrl}/logout`, {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      setMessage('Logout failed');
    }
  };

  return (
    <div className="homepage-container">
      <div className="homepage-box">
        <nav className="homepage-nav">
          <button className="homepage-navButtonLeft" onClick={handleLogout}>Logout</button>
          
          {/* Dropdown Button with new name "My Account" */}
          <div className="homepage-accountDropdownContainer">
            <button className="homepage-navButtonRight" onClick={() => setShowDropdown(!showDropdown)}>
              My Account
            </button>
            {showDropdown && (
              <div className="homepage-dropdown">
                <button className="homepage-dropdownButton" onClick={handleClickProfile}>
                  Profile
                </button>
                <button className="homepage-dropdownButton" onClick={handleClickSchedule}>
                  Schedule
                </button>
                <button className="homepage-dropdownButton" onClick={handleClickPendingTransactions}>
                  Transactions
                </button>
              </div>
            )}
          </div>
        </nav>
        <img src={logo} alt="CUNY Course Sniper Logo" className="homepage-logo" />
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {enrolledCollege && (
          <div className="homepage-enrolledCollegeButtonContainer">
            <button 
              className="homepage-enrolledCollegeButton"
              style={{ backgroundColor: '#142d4c' }}  
            >
              {enrolledCollege}
              <div className="homepage-enrolledTermDropdown">
                {terms.map(term => (
                  <button 
                    key={term.slug}
                    className="homepage-termButton"
                    onClick={() => {
                      const collegeSlug = enrolledCollege.replace(/\s+/g, '-');
                      navigate(`/searchPage/${collegeSlug}/${term.slug}`);
                    }}
                  >
                    {term.name}
                  </button>
                ))}
              </div>
            </button>
          </div>
        )}
        <CollegeGrid colleges={sortedColleges} />
        <div className="homepage-footerText">
          Don't see your college? Try the search bar
        </div>
      </div>
    </div>
  );
};

export default Home;
