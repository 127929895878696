// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ element }) => {
  console.log("made it to the pageeee")
  const { isAuthenticated, loading } = useContext(AuthContext);
  console.log("did we get authenticated: " + isAuthenticated)
  

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking auth
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
