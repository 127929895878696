import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from './logo192.png';
import { ThreeDots } from 'react-loader-spinner';


function VerifyEmail() {
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);  // State for the loading spinner
  const location = useLocation();
  const { email } = location.state; // Extract email from state

  const handleResendCode = async () => {
    try {
      setLoading(true);  // Show the spinner
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';
      const response = await axios.post(`${backendUrl}/resend-verification-code`, { personal_email: email }, {withCredentials: true});
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Failed to resend verification code');
    } finally {
      setLoading(false);  // Hide the spinner
    }
  };

const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);  // Show the spinner

    const data = {
      personal_email: email,
      verification_code: verificationCode,
    };

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';
      const response = await axios.post(`${backendUrl}/verify-email`, data);
      setMessage(response.data.message);
      console.log(response.data.message);
      if (response.data.message.toLowerCase() === 'account created successfully') {
        // console.log("Account created successfully");
        // Use the navigate function declared at the top of your component
        navigate('/login', { replace: true });
        console.log('Navigated')
      }
    } catch (error) {
      setMessage('Verification failed');
    } finally {
      setLoading(false);  // Hide the spinner
    }
  };
  
  
  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <img src={logo} alt="CUNY Course Sniper Logo" style={styles.logo} /> {/* Logo added here */}
        {/* <h2 style={styles.title}>CUNY Course Sniper</h2> */}
        <h3 style={styles.subtitle}>Verify Email</h3>
        <form onSubmit={handleVerify}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Verification Code:</label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              style={styles.input}
              disabled={loading}  // Disable input during loading
            />
          </div>
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? "Verifying..." : "Verify"}
          </button>
        </form>

        {/* Show the loader when loading */}
        {loading && (
          <div style={styles.loaderContainer}>
            <ThreeDots color="#007bff" height={40} width={40} />
          </div>
        )}

        <p
          className="link"
          style={{
            textAlign: 'center',
            marginTop: '10px',  // Add some margin if needed
          }}
        >
          <button
            onClick={handleResendCode}
            style={{ border: 'none', background: 'none', color: '#007bff', cursor: 'pointer' }}
            disabled={loading}  // Disable button during loading
          >
            Send another code
          </button>
        </p>
        <p
          className="link"
          style={{
            textAlign: 'center',
            marginTop: '10px' // Add some margin if needed
          }}
        >
          <Link to="/signup">Go back to Signup</Link>
        </p>
        {message && <p style={message === 'Verification failed' ? styles.errorMessage : styles.successMessage}>{message}</p>}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fff',
  },
  box: {
    maxWidth: '400px',
    width: '100%',
    padding: '35px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '275px', // Increase the size of the logo
    marginBottom: '5px', // Add more space below the logo to lower it
  },
  // title: {
  //   textAlign: 'center',
  //   color: '#007bff',
  // },
  subtitle: {
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '20px', // Adjusted the margin for better spacing
    fontSize: '24px',  // Increased font size
    fontWeight: 'bold', // Made the subtitle bold
    color: '#333',  // Set a dark grey color for a more subtle look
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#142d4c',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
  errorMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    marginTop: '20px',
    textAlign: 'center',
    color: 'green',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
};

export default VerifyEmail;
