import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ProfileContext } from './ProfileContext';
import { useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import './MySchedule.css';  // Import CSS for styling
import { collegeCreditInfo } from './collegecredits';  // Import the college credits dictionary


const colleges = [
  'Baruch College',
  'Borough of Manhattan CC',
  'Bronx CC',
  'Brooklyn College',
  'City College',
  'College of Staten Island',
  'Graduate Center',
  'Guttman CC',
  'Hostos CC',
  'Hunter College',
  'John Jay College',
  'Kingsborough CC',
  'LaGuardia CC',
  'Lehman College',
  'Macaulay Honors College',
  'Medgar Evers College',
  'NYC College of Technology',
  'Queens College',
  'Queensborough CC',
  'School of Journalism',
  'School of Labor & Urban Studies',
  'School of Law',
  'School of Medicine',
  'School of Professional Studies',
  'School of Public Health',
  'York College'
];

const MySchedule = () => {
  const { profile } = useContext(ProfileContext);
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedClass, setSelectedClass] = useState(null); // To store the clicked class info
  const navigate = useNavigate();
  const location = useLocation();

  // Access the passed state

  const {
    classToAdd: initialClassToAdd,
    selectedCollege: initialSelectedCollege,
    selectedTerm: initialSelectedTerm,
    predeterminedClassesToDrop: initialClassesToDrop, // class_ids to drop
    thisanedit: initialThisAnEdit,
  } = location.state || {};
  // console.log('Received College:', initialSelectedCollege);
  // console.log('Received Term:', initialSelectedTerm);
  console.log('Received Class to Add:', initialClassToAdd);
  // Initialize your state variables with the passed values or defaults
  const [selectedCollege, setSelectedCollege] = useState(initialSelectedCollege || '');
  const [selectedTerm, setSelectedTerm] = useState(initialSelectedTerm || '');
  const [classToAdd, setClassToAdd] = useState(initialClassToAdd || null);
  const [classesToDrop, setClassesToDrop] = useState(new Set(initialClassesToDrop || []));  // Initialize with provided classes
  const [thisAnEdit, setThisAnEdit] = useState(initialThisAnEdit || false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [backendMessage, setBackendMessage] = useState(''); // To store the response from the ec2 backend
  const [isFetching, setIsFetching] = useState(false);  // New state for showing loading spinner during fetch
  const [missingCredentials, setMissingCredentials] = useState(false);  // New state for missing CUNY credentials
  const [searchTerm, setSearchTerm] = useState('');  // State for the search input
  const [searchResults, setSearchResults] = useState([]); 
  const [isSearching, setIsSearching] = useState(false); // New state for showing loading spinner during search
  const [filteredResults, setFilteredResults] = useState([]); // State for storing filtered results
  const [showModal, setShowModal] = useState(false);
  // State for managing whether to confirm drop of the conflicting class
  const [conflictingClass, setConflictingClass] = useState(null);
  const [confirmDrop, setConfirmDrop] = useState(false);
  const [termCreditLimit, setTermCreditLimit] = useState(null);
  // State to track the default credit limit based on college and term
  const [defaultCreditLimit, setDefaultCreditLimit] = useState(null);
  // State to track any user adjustments to the credit limit
  const [userDefinedCreditLimit, setUserDefinedCreditLimit] = useState(null);
  const [classDetailsOpen, setClassDetailsOpen] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for showing loading spinner during submit for adding/dropping classes
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';  // Use environment variable or default to localhost

  const confirmLoggedIn = async () => {
    try {
      // Send a request to check if the user is authenticated
      const response = await axios.get(`${backendUrl}/check-login`, {
        withCredentials: true,
      });
  
      // console.log('User is logged in:', response.data); // Log if the user is authenticated
      return true;  // Return true if the user is logged in
    } catch (error) {
      console.error('User is not logged in:', error); // Log error details
      if (error?.response?.status === 401) {
        console.log('Redirecting to login page');
        navigate('/login', { replace: true }); // Redirect to login if not authenticated
      }
      return false; // Return false if not logged in
    }
  };
  
  const toggleClassDetails = (index) => {
    setClassDetailsOpen(prev => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  useEffect(() => {
    // Check if the user is logged in before fetching the schedule
    confirmLoggedIn();
    // Initialize the array with 'false' for each class
    setClassDetailsOpen(schedule.map(() => false));
  }, [schedule]);

  const toggleClassToDrop = classId => {
    setClassesToDrop(prev => {
      const newSet = new Set(prev);
      if (newSet.has(classId)) {
        newSet.delete(classId);
      } else {
        newSet.add(classId);
      }
      return newSet;
    });
  };


  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // January is 0, December is 11

  // If it's after August, Winter and Spring belong to the next year
  const nextYear = currentMonth >= 8 ? currentYear + 1 : currentYear;
  const terms = [
    // { name: `Summer ${currentYear}`, slug: `${currentYear}-Summer-Term` },
    { name: `Fall ${currentYear}`, slug: `${currentYear} Fall Term` },
    { name: `Winter ${nextYear}`, slug: `${nextYear} Winter Term` },
    { name: `Spring ${nextYear}`, slug: `${nextYear} Spring Term` },
  ];

  const handleSearch = () => {
    if (!searchTerm) {
      // Sort the results even when the searchTerm is empty
      setFilteredResults(
        [...searchResults].sort((a, b) => 
          (a.class_code || '').localeCompare(b.class_code || '')
        )
      );
      return;
    }
  
    // Filter results based on the search term
    const filteredResults = searchResults.filter(cls =>
      (cls.class_code || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (cls.instructor || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (cls.course_topic || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (cls.class_number || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Sort the filtered results alphabetically by class_code, safely handling null values
    const sortedResults = filteredResults.sort((a, b) =>
      (a.class_code || '').localeCompare(b.class_code || '')
    );
  
    // Set the filtered and sorted results state
    setFilteredResults(sortedResults);
  };
  
  // Effect to trigger handleSearch when searchTerm changes
  useEffect(() => {
    handleSearch(); // Filter when searchTerm changes
  }, [searchTerm]);
  
  useEffect(() => {
    setLoading(false); // Set loading to false initially since we only want to trigger loading on button click
  }, []);

  useEffect(() => {
    // console.log("Search Results:", searchResults); // Check the results being fetched
  }, [searchResults]);

  // Ensure the results are displayed on the frontend
  useEffect(() => {
    // console.log("Filtered Results:", filteredResults);
  }, [filteredResults]);
  
  useEffect(() => {
    fetchCurrentSchedule(); // Fetch current schedule on load or when selected term changes
  }, [selectedTerm]); // Depend on selectedTerm to refetch when it changes
  

  useEffect(() => {
    // Fetch the dataset from the backend when term and college are selected
    const fetchData = async () => {
      if (!selectedTerm || !selectedCollege) return;
  
      setIsFetching(true); // Show loader while fetching
  
      try {
        // Fetch the dataset from the backend based on selected term and college
        const response = await axios.get(`${backendUrl}/search-classes`, {
          params: {
            term: selectedTerm,
            college: selectedCollege,
          },
        });
  
        // Ensure the response contains the expected 'classes' field
        if (response.data && response.data.classes) {

          const sortedClasses = response.data.classes
            .filter(cls => cls.class_code && cls.class_code.trim() !== '') // Filter out blank class_code
            .sort((a, b) => a.class_code.localeCompare(b.class_code)); // Sort alphabetically by class_code
          setSearchResults(sortedClasses); // Set the dataset in alphabetical order
          setFilteredResults(sortedClasses); // Set the filtered dataset in alphabetical order
          // console.log("Fetched Classes:", response.data.classes); // Log to verify data
        } else {
          setSearchResults([]); // Clear if no classes are found
          setFilteredResults([]); // Clear filtered results
          // console.log("No classes found in response:", response.data); // Log response
        }
      } catch (error) {
        console.error('Error fetching classes:', error);
        setSearchResults([]); // Clear on error
        setFilteredResults([]); // Clear filtered results
      } finally {
        setIsFetching(false); // Hide loader after fetching
      }
    };
  
    fetchData();    
  }, [selectedTerm, selectedCollege]); // Trigger on term and college change

  useEffect(() => {
    if (selectedCollege && collegeCreditInfo[selectedCollege]) { // Ensure the college is selected and exists in the dictionary
      const limits = collegeCreditInfo[selectedCollege];
      let newLimit = limits.maxCreditsWithoutOverload; // Default to regular term limit
      
      if (selectedTerm.includes('Winter')) {
        newLimit = limits.winterMaxCreditsWithoutOverload;
      } else if (selectedTerm.includes('Summer')) {
        newLimit = limits.summerMaxCreditsWithoutOverload;
      }
  
      setTermCreditLimit(newLimit || null); // Update the state with new limit or null if undefined
      setDefaultCreditLimit(newLimit || null); // Set the default limit as well
    } else {
      setTermCreditLimit(null); // Reset the credit limit if the selection is invalid
    }
  }, [selectedCollege, selectedTerm]); // Only re-run when these values change


  // Use Effect that when redirected to this page from another page with a classToAdd, it will automatically open the modal
  useEffect(() => {
    if (classToAdd) {
      setShowModal(true);
    }
  }, [classToAdd]);

  // Function to render the current effective credit limit
  const effectiveCreditLimit = userDefinedCreditLimit || defaultCreditLimit;

  const fetchCurrentSchedule = async () => {
    if (!selectedTerm) return; // Exit if no term is selected
    setLoading(true);
    try {
      // const token = sessionStorage.getItem('jwtToken');
      const email = sessionStorage.getItem('email');
      console.log("Made it here");
      // Request to fetch the current schedule based on the selected term
      const response = await axios.get(`${backendUrl}/current-schedule`, {
        withCredentials: true,
        headers: {
          Email: email,
        },
        params: {
          Term: selectedTerm,
        },
      });
  
      if (response.data && response.data.classes) {
        setSchedule(response.data.classes); // Correctly set the schedule with fetched data
        console.log("Fetched Schedule:", response.data.classes);
        setBackendMessage("Displaying your latest schedule. To refresh with the most current updates, please click 'Fetch Updated Schedule.'"); // Clear any previous messages
      } else {
        console.log("No classes found in response:", response.data);
      }
    } catch (error) {
        console.log("Error fetching schedule:", error.response); // Correctly log the error response
        // Check for specific status codes to set the custom message
        console.log("Error status code:", error.response.status);
        if (error.response.status === 401) {
          console.log("Redirecting to login page");
          // Redirect to the login page
          navigate('/login', { replace: true }); // true stops the user from going back to the previous page
        } else if (error.response && error.response.status === 404) {
          setBackendMessage("You currently do not have any classes for the selected term. To check for the latest updates, please click 'Fetch Updated Schedule' for this semester.");
          setSchedule([]); // Clear the schedule if no classes are found
        } else {
          setBackendMessage('Failed to load the current schedule.'); // Generic error message
        }
      } finally {
        setLoading(false);
      }
  };


  
  if (loading) {
    return (
      <div className="schedule-loading-indicator">
        <ThreeDots
          color = '#9fd3c7'
          ariaLabel="three-dots-loading"
          visible={true}
        />
        <p className="schedule-loading-message">Loading your schedule, please wait...</p>
      </div>
    );
  }

  
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page in history
  };  

  const handleClickProfile = (e) => {
    e.preventDefault();
    navigate('/profile');
  };

  const handleClickPendingTransactions = (e) => {
    e.preventDefault();
    navigate('/PendingTransactions');
  }
  


  const handleLogout = async () => {
    await axios.post(`${backendUrl}/logout`, {}, { withCredentials: true });
    navigate('/login');
  };

  // Modal handler for displaying more class info
  const handleClassClick = (classData) => {
    setSelectedClass(classData);
  };

  const closeModal = () => {
    setSelectedClass(null);
  };

  const handleCollegeChange = (e) => {
    setSelectedCollege(e.target.value);
  };
  
  const handleTermChange = (e) => {
    setSelectedTerm(e.target.value);
  };

  
  
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const classColors = [
    '#CD5C5C',  // Light Maroon (IndianRed)
    '#90EE90',  // Light Green (LightGreen)
    '#ADD8E6',  // Light Navy (LightBlue)
    '#D3D3D3',  // Light Olive (LightGray)
    '#DDA0DD',  // Light Purple (Plum)
    '#20B2AA',  // Light Teal (LightSeaGreen)
    '#FFA500',  // Orange
    '#FF00FF',  // Fuchsia
    '#00FFFF',  // Aqua
    '#FFFFE0',  // Light Yellow (LightYellow)
    '#87CEFA',  // Light Blue (LightSkyBlue)
    '#FF6347',  // Light Red (Tomato)
    '#696969',  // Light Black (DimGray)
    '#7FFF00',  // Chartreuse
    '#FFF8DC'   // Cornsilk
  ];

  // Move parseDaysTimes function outside the rendering logic so it's accessible in the modal
  const parseDaysTimes = (daysTimes) => {
    const dayMapping = {
      'Mo': 'Monday',
      'Tu': 'Tuesday',
      'We': 'Wednesday',
      'Th': 'Thursday',
      'Fr': 'Friday',
      'Sa': 'Saturday',
      'Su': 'Sunday',
    };
  
    // Regex to match time and ensure space between time and AM/PM
    const regex = /([A-Za-z]{2})([A-Za-z]{2})?\s(\d{1,2}:\d{2})([APM]+)-(\d{1,2}:\d{2})([APM]+)/g;
    let match;
    const result = [];
  
    while ((match = regex.exec(daysTimes)) !== null) {
      const day1 = dayMapping[match[1]];  // Map first day abbreviation
      const day2 = match[2] ? dayMapping[match[2]] : null; // Map second day abbreviation if present
      let startTime = `${match[3]} ${match[4]}`;  // Add space between time and AM/PM
      let endTime = `${match[5]} ${match[6]}`;    // Add space between time and AM/PM
  
      result.push({ day: day1, start_time: startTime, end_time: endTime });
      if (day2) {
        result.push({ day: day2, start_time: startTime, end_time: endTime });
      }
    }
    return result;
  };

  
  // Helper function to convert time to minutes from 7:00 AM
  const convertTimeToMinutes = (time) => {
    const [hourMinute, period] = time.split(' ');
    let [hours, minutes] = hourMinute.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
  
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
  
    const totalMinutes = (hours * 60) + minutes;
    const startTimeInMinutes = 7 * 60; // Minutes since 7:00 AM
    return totalMinutes - startTimeInMinutes;
  };


  // Updated row span calculation function
  const calculateRowSpan = (startTime, endTime) => {
    const startMinutes = convertTimeToMinutes(startTime);
    const endMinutes = convertTimeToMinutes(endTime);
    const totalMinutes = endMinutes - startMinutes;
    
    // console.log(`Start Time: ${startTime}, End Time: ${endTime}, Row Span: ${totalMinutes} rows`);
    return Math.ceil(totalMinutes / 5); ;  // Each 5 minutes corresponds to 1 row
  };

  
  const fetchUpdatedSchedule = async () => {
    if (!selectedTerm) {
      setBackendMessage('Please select a term to proceed.');
      return;
    }
    setBackendMessage(false);  // Clear previous message
    setMissingCredentials(false);  // Clear missing credentials message
    setIsFetching(true);  // Start loading
    const { cuny_username, cuny_password } = profile;

    // Check if CUNY credentials are missing
    if (!cuny_username || !cuny_password) {
      setMissingCredentials(true);  // Set state to show the missing credentials message
      setIsFetching(false);  // Stop loading
      return;  // Exit the function if credentials are missing
    }

    try {
      const token = sessionStorage.getItem('jwtToken');
      const email = sessionStorage.getItem('email');

      // Make request to your backend
      const response = await axios.get(`${backendUrl}/fetch-schedule`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
          Email: email ,
        },
        params: {
          // College: selectedCollege,
          Term: selectedTerm,
        },
      });

      if (response.data && response.data.classes) {
        setSchedule(response.data.classes);  // Ensure 'classes' are set correctly
      }
      setBackendMessage(response.data.message);  // Set backend response
    } catch (error) {
      setBackendMessage('Failed to connect to the backend');
    } finally {
      setIsFetching(false);  // Stop loading after request completes
    }
  };

  // Function to check time overlap between two time intervals
  const checkOverlap = (existingClass, newClass) => {
    const existingTimes = parseDaysTimes(existingClass.days_times);
    const newTimes = parseDaysTimes(newClass.days_times);
  
    for (let e of existingTimes) {
      for (let n of newTimes) {
        if (e.day === n.day) {
          const existingStart = convertTimeToMinutes(e.start_time);
          const existingEnd = convertTimeToMinutes(e.end_time);
          const newStart = convertTimeToMinutes(n.start_time);
          const newEnd = convertTimeToMinutes(n.end_time);
  
          if (
            (newStart >= existingStart && newStart < existingEnd) ||
            (newEnd > existingStart && newEnd <= existingEnd) ||
            (existingStart >= newStart && existingEnd <= newEnd)
          ) {
            return existingClass; // Return the conflicting class
          }
        }
      }
    }
    return null; // No overlap
  };
  
  const calculateTotalCredits = () => {
    const totalCredits = schedule.reduce((acc, curr) => acc + curr.class_credits, 0);
    return totalCredits;
  };
  
  
  const calculateAdjustedCredits = () => {
    // Adding the credits of the class you want to add (if any)
    const creditsToAdd = classToAdd ? parseFloat(classToAdd.class_credits) : 0;
  
    // Subtracting the credits of the classes you plan to drop
    const creditsToDrop = Array.from(classesToDrop).reduce((acc, classId) => {
      const found = schedule.find(cls => cls.class_id === classId);
      return acc + (found ? parseFloat(found.class_credits) : 0);
    }, 0);
  
    // Sum of current credits
    const currentCredits = schedule.reduce((acc, cls) => acc + parseFloat(cls.class_credits), 0);
  
    // Adjusted total credits calculation
    const adjustedCredits = currentCredits - creditsToDrop + creditsToAdd;
  
    return adjustedCredits;
  };
  
const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
  // Check for credit limit
  const effectiveCreditLimit = typeof userDefinedCreditLimit === 'number' 
    ? userDefinedCreditLimit 
    : termCreditLimit;

  const totalCredits = calculateAdjustedCredits();

  if (totalCredits > effectiveCreditLimit) {
    alert(`Total credits exceed your limit of ${effectiveCreditLimit}. Please adjust your schedule.`);
    setIsSubmitting(false);
    return;
  }

  // Check for overlaps with the current schedule
  const conflictingClass = schedule.find((existingClass) =>
    checkOverlap(existingClass, classToAdd)
  );

  if (conflictingClass && !classesToDrop.has(conflictingClass.class_id)) {
    alert(
      "The class you're trying to add overlaps with another class in your schedule."
    );
    setIsSubmitting(false);
    return;
  }

  try {
    const user_email = sessionStorage.getItem('email');
    const user_membership = sessionStorage.getItem('membership_level');
    // console.log(sessionStorage)
    // console.log('Membership:', user_membership);
    // Prepare data to send to the backend
    const payload = {
        added_class_id: classToAdd?.class_id,
        dropped_class_ids: Array.from(classesToDrop), // Convert Set to Array
        term: selectedTerm,
    };

    // Send the payload to the backend and await the response
    const response = await axios.post(`${backendUrl}/schedule-change-request`, 
        payload,  // Payload goes as the second argument
        { withCredentials: true }
    );

    // Handle backend response
    if (response.status === 200) {
      alert('Your request has been submitted successfully! Changes will be applied when the class opens.');
    
    } else {
      alert('Request failed. Please try again.');
    }

    // Reset state and close modal
    setShowModal(false);
    setClassToAdd(null);
    setClassesToDrop(new Set());
    setConflictingClass(null);
    setThisAnEdit(false);
    setIsSubmitting(false);
  } catch (error) {
    console.error('Error submitting schedule change request:', error);
    alert('Failed to submit your request. Please try again later.');
  }
  
};
  
  const handleAddClass = (classData) => {
    // Check if there's an overlap and set the conflicting class state
    const conflict = schedule.find((existingClass) => checkOverlap(existingClass, classData));
    setConflictingClass(conflict);
    setClassToAdd(classData);
    setShowModal(true); // Show the modal when the button is clicked
  };

  const handleDropConflictingClass = (classId) => {
    toggleClassToDrop(classId); // Use the same toggle function to manage state
  };

  const handlePrerequisiteCheckboxChange = (e) => {
    e.stopPropagation(); // Prevent any parent handlers from being executed
    // Your existing logic here
  };

  const handleNoHoldsChange = (e) => {
    e.stopPropagation(); // Prevent any parent handlers from being executed
  };

  const handleHoldCheckboxChange = (e) => {
    e.stopPropagation(); // Prevent any parent handlers from being executed
  };


  
  return (
    <div className="schedule-page-container">
      {/* Top navigation buttons */}
      <div className="schedule-nav">
        <button onClick={handleGoBack} className="go-back-button">Go Back</button>
        
        {/* Dropdown Button with "My Account" */}
        <div className="schedule-accountDropdownContainer">
          <button className="schedule-navButtonRight" onClick={() => setShowDropdown(!showDropdown)}>
            My Account
          </button>
          {showDropdown && (
            <div className="schedule-dropdown">
              <button className="schedule-dropdownButton" onClick={handleClickPendingTransactions}>
                Transactions
              </button>
              <button className="schedule-dropdownButton" onClick={handleClickProfile}>
                Profile
              </button>
              <button className="schedule-dropdownButton" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
      <h1>Your Weekly Schedule</h1>
      <p style={{ color: 'red' }}>
        Note: This page is currently under development. Both its appearance and functionality are subject to change. 
        Please be aware that this is just a demo and does not reflect your actual schedule.
      </p>
      <div className="schedule-main-container">
        <div className="schedule-form-container">
          <form className="schedule-form">
            <div className="form-group">
              <label>Select Term:</label>
              <select value={selectedTerm} onChange={handleTermChange}>
                <option value="">Select Term</option>
                {terms.map((term) => (
                  <option key={term.slug} value={term.slug}>
                    {term.name}
                  </option>
                ))}
              </select>
            </div>
            <button type="button" className="fetch-schedule-button" onClick={fetchUpdatedSchedule}>
              <strong>Fetch Updated Schedule</strong>
            </button>

            <div className="form-group">
              <label>Select College:</label>
              <select value={selectedCollege} onChange={handleCollegeChange} disabled={!selectedTerm}>
                <option value="">Select College</option>
                {colleges.map((college) => (
                  <option key={college} value={college}>
                    {college}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <input
                type="text"
                placeholder="Search for classes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                className="schedule-class-search-input"
                disabled={!selectedTerm || !selectedCollege}
              />
            </div>

            <button
              type="button"
              className="schedule-search-classes-button"
              onClick={handleSearch}
              disabled={!selectedTerm || !selectedCollege || isSearching}
            >
              {isSearching ? (
                <ThreeDots color="#385170" height={20} width={20} />
              ) : (
                <strong>Search Classes</strong>
              )}
            </button>
          </form>
          {searchResults.length === 0 && selectedTerm && selectedCollege && !isFetching && (
            <p className="schedule-class-search-error">
              No classes are currently available for the selected term and college. Please try again with a different selection.
            </p>
          )}
          <p className="schedule-form-note">
            <em>Note: Select a term to fetch your schedule. Choose a college, term, and enter a search term to find classes.</em>
          </p>
          <p className="schedule-click-transactions">
            <em>
              Click <span onClick={handleClickPendingTransactions} style={{ cursor: 'pointer', textDecoration: 'underline' }}>here</span> to view your pending transactions
            </em>
          </p>
        </div>

        {/* Display Search Results Below the Search Bar */}
        {filteredResults.length > 0 && (
          <div className="schedule-search-results-container">
            <h3>Search Results</h3>
            <ul className="schedule-search-results-list">
              {filteredResults.map((classData, index) => (
                <li key={index} className="schedule-search-result-item">
                  <div className="schedule-search-class-card">
                    <h4>{classData.class_code}, {classData.instructor}, {classData.class_number}
                    <button className="schedule-add-class-button" onClick={() => handleAddClass(classData)}>
                      Add
                    </button>
                    </h4>
                    {/* Details are hidden by default and shown on hover */}
                    <div className="schedule-search-class-details">
                      <p> <strong> Course Topic: </strong> {classData.course_topic}</p>
                      <p> <strong> Enrollment Status: </strong> {classData.status}</p>
                      <p> <strong> Location: </strong> {classData.room}</p>
                      <p>
                        <strong>Days and Times:</strong>{" "}
                        {parseDaysTimes(classData.days_times)
                          .map(({ day, start_time, end_time }) => `${day}: ${start_time} - ${end_time}`)
                          .join(", ")}
                      </p>
                      <p> <strong> Instructor: </strong> {classData.instructor}</p>
                      <p> <strong> Instruction Mode: </strong> {classData.instruction_mode}</p>
                      <p> <strong> Meeting Dates: </strong> {classData.meeting_dates}</p>

                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {isFetching && (
        <div className="schedule-loaderContainer">
          <ThreeDots color="#385170" height={40} width={40} />  {/* Navy color for the loader */}
          <p className="schedule-loaderMessage">
            This could take up to 1 min, be patient...
          </p>
        </div>
      )}
      {missingCredentials && (
        <div className="schedule-missing-credentials-warning">
          <p>
            Your CUNY credentials are missing. Please update them in your profile {' '}
            <span className="schedule-update-profile-link" onClick={handleClickProfile}>
              here,
            </span>{' '}
            and try again.
          </p>
        </div>
      )}

      {backendMessage && (
        backendMessage === 'Error in regards to your cuny credentials' ? (
          <div className="schedule-missing-credentials-warning">
            <p>
              There is an issue with your CUNY credentials, please update them in your profile {' '}
              <span className="schedule-update-profile-link" onClick={handleClickProfile}>
                here.
              </span>
            </p>
          </div>
        ) : (
          <p className="schedule-backend-response">{backendMessage}</p>
        )
      )}
      {/* Question form for adding class */}
      {showModal && (
        <div className="schedule-add-class-modal">
          <div className="schedule-add-class-modal-form">
            <h2>Confirm Adding {classToAdd.class_code}: {classToAdd.class_number} - {classToAdd.class_credits} credits</h2>
            <form onSubmit={handleSubmit}>
              {/* Check for conflicting class */}
              {/* Display default or adjusted credit limit */}
              
                <p className="current-credit-limit">
                  Current Credit Limit: {effectiveCreditLimit}
                </p>
              <div className="credit-limit-display">
                <div className="adjust-credit-limit-container">
                  <label>
                    Doesn't seem right? Adjust your credit limit:
                  </label>
                  <input
                    type="checkbox"
                    className="credit-limit-custom-checkbox"
                    checked={typeof userDefinedCreditLimit === 'number'}
                    onChange={(e) => {
                      if (e.target.checked && userDefinedCreditLimit === null) {
                        setUserDefinedCreditLimit(defaultCreditLimit); // Initialize with default when first checked
                      } else {
                        setUserDefinedCreditLimit(null); // Reset when unchecked
                      }
                    }}
                  />
                </div>
                {typeof userDefinedCreditLimit === 'number' && (
                    <input
                      className="credit-limit-input"
                      type="number"
                      value={userDefinedCreditLimit}
                      onChange={(e) => setUserDefinedCreditLimit(Number(e.target.value))}
                      min={0}
                    />
                  )}
              </div>
              <div className="conflict-info-container">
                <p>Adjusted Total Credits After Drops/Adds:
                  <span className="highlighted-number">
                    {calculateAdjustedCredits()}
                  </span>
                </p>
                {/* Class Conflicts */}
                {conflictingClass ? (
                  <>
                    <p>
                      This class overlaps with <strong>{conflictingClass.class_code}</strong> scheduled at{' '}
                      <strong>
                        {parseDaysTimes(conflictingClass.days_times)
                          .map(({ day, start_time, end_time }) => `${day}: ${start_time} - ${end_time}`)
                          .join(', ')}
                      </strong>.
                    </p>
                    <p>Would you like to drop the conflicting class in the theoretical scenario that this class were to open up?</p>
                    <button
                      type="button"
                      onClick={() => handleDropConflictingClass(conflictingClass.class_id)} // Adjusted for checkbox functionality
                      className="conflicting-drop-button"
                    >
                      {classesToDrop.has(conflictingClass.class_id) ? 'Undo Drop' : 'Drop Conflicting Class'}
                    </button>
                  </>
                ) : (
                  <>
                    <p>No conflicts detected.</p>
                  </>
                )}
              </div>
              {/* List of classes to potentially drop */}
              <div className="drop-classes-section">
                <h3>Select Other Classes to Drop (if needed):</h3>
                <ul className="drop-classes-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {schedule.map((cls, index) => (
                    <li key={index} className="drop-class-item">
                    <div className="drop-class-card">
                      <div className="drop-class-summary">
                        <h4>{cls.class_code} - {cls.class_credits} credits</h4>
                        <div>
                          <button
                            type = "button"
                            className="drop-class-button"
                            onClick={() => toggleClassToDrop(cls.class_id)}
                            aria-pressed={classesToDrop.has(cls.class_id)}
                          >
                            {classesToDrop.has(cls.class_id) ? 'Undo Drop' : 'Drop Class'}
                          </button>
                          <button 
                            type = "button"
                            className="drop-class-details-button"
                            onClick={(e) => {
                              e.stopPropagation();  // Prevent the dropdown from closing when clicking the toggle button
                              toggleClassDetails(index);
                            }}
                          >
                            {classDetailsOpen[index] ? 'Hide Details' : 'Show Details'}
                          </button>
                        </div>
                      </div>
                      {classDetailsOpen[index] && (
                        <div className="drop-class-details">
                        <p> <strong> Course Topic: </strong> {cls.course_topic}</p>
                        <p> <strong> Enrollment Status: </strong> {cls.status}</p>
                        <p> <strong> Location: </strong> {cls.room}</p>
                        <p>
                          <strong>Days and Times:</strong>{" "}
                          {parseDaysTimes(cls.days_times)
                            .map(({ day, start_time, end_time }) => `${day}: ${start_time} - ${end_time}`)
                            .join(", ")}
                        </p>
                        <p> <strong> Instructor: </strong> {cls.instructor}</p>
                        <p> <strong> Instruction Mode: </strong> {cls.instruction_mode}</p>
                        <p> <strong> Class Number: </strong> {cls.class_number}</p>
                        <p> <strong> Meeting Dates: </strong> {cls.meeting_dates}</p>
  
                      </div>
                      )}
                    </div>
                  </li>
                  
                  ))}
                </ul>
              </div>


              {/* Prerequisites Check */}
              {/* Prerequisites Display */}
              {classToAdd.prerequisites && (
                <div className="add-class-prerequisite-section">
                  <p>
                    <strong>Prerequisites:</strong> {classToAdd.prerequisites}
                  </p>
                  <p>
                    <strong> Have you completed all the listed prerequisites? </strong>
                  </p>
                  <div className="prerequisite-checkbox-container">
                    <input
                      type="checkbox"
                      id="prerequisitesCompleted"
                      onChange={handlePrerequisiteCheckboxChange}
                      required
                    />
                    <label
                      htmlFor="prerequisitesCompleted"
                      className="prerequisite-checkbox-label" // Add this class
                    >
                      Yes, I have completed the prerequisites
                    </label>
                  </div>
                </div>
              )}
              {/* No Holds Confirmation */}
              <div className="no-holds-confirmation">
                <input
                  type="checkbox"
                  id="noHoldsCheckbox"
                  onChange={handleNoHoldsChange}
                  required
                  style={{ marginRight: '5px' }}
                  />
                <label htmlFor="noHoldsCheckbox">
                  I confirm that my CUNY account has no holds.
                </label>
              </div>

              {/* List of classes to potentially drop for Confirmation */}
              <div className="drop-classes-confirmation">
                <h3>CLASSES YOU WILL DROP IF THIS CLASS IS ADDED:</h3>
                <div className="drop-classes-list-confirmation">
                  {Array.from(classesToDrop).map((classId) => {
                    const found = schedule.find((cls) => cls.class_id === classId);
                    return found ? (
                      <div key={classId} className="drop-class-confirmation-item">
                        <h4>{found.class_code} - {found.class_credits} credits</h4>
                      </div>
                    ) : (
                      <p key={classId}>Class not found in your schedule.</p>
                    );
                  })}
                </div>
                {/* Checkbox to confirm listed dropped classes */}
                <div className="dropped-classes-checkbox-container">
                  <input
                    type="checkbox"
                    id="ConfirmDroppedClassesCheckbox"
                    onChange={handleHoldCheckboxChange}
                    required
                    style={{ marginRight: '5px' }}
                    />
                  <label htmlFor="ConfirmDroppedClassesCheckbox">
                    I confirm that I will drop the listed classes when <span style={{ display: 'block' }}> {classToAdd.class_code}: ({classToAdd.class_number}) is added.</span>
                  </label>
                </div>
              </div>

              


              {/* Confirm Adding the Class */}
              <button type="submit" className="confirm-add-button" disabled={isSubmitting}>
                {isSubmitting ? (
                  <ThreeDots color="#FFFFFF" height={10} width={20} />
                ) : (
                  thisAnEdit ? 'Confirm Changes' : 'Confirm Add'
                )}
              </button>
              {/* When clicked i want it to change to a loading 3 dots*/ }

              <button
                type="button"
                onClick={() => {
                  setShowModal(false);
                  setClassesToDrop(new Set());
                  setThisAnEdit(false);
                }}
                className="cancel-add-button"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      
      {/* Schedule Grid */}

      <div className="schedule-container">
        <div className="calendar-grid">
          {/* Empty corner for alignment */}
          <div className="empty-cell"></div>

          {/* Render days of the week (X-axis) */}
          {days.map((day) => (
            <div key={day} className="calendar-day">
              {day}
            </div>
          ))}

          {/* Render time slots (Y-axis) */}
          {Array.from({ length: 16 }, (_, timeIndex) => {
            const hour = timeIndex + 7; // Starting at 7:00 AM
            let formattedHour;
            if (hour === 12) {
              formattedHour = `12:30 PM`; // Noon shifted to 12:30
            } else if (hour > 12) {
              formattedHour = `${hour - 12}:30 PM`; // Convert to PM with :30
            } else {
              formattedHour = `${hour}:30 AM`; // AM times with :30
            }

            return (
              <React.Fragment key={timeIndex}>
                {/* Time Slot Labels */}
                <div className="calendar-time-slot" style={{ gridRow: `span 12` }}>{formattedHour}</div>
              </React.Fragment>
            );
          })}
          


          {/* Render class cards directly inside the grid */}
          {schedule && schedule.length > 0 ? (
            schedule.map((classData, index) => {
              const parsedDaysTimes = parseDaysTimes(classData.days_times);  // Parse days and times
              return parsedDaysTimes.map(({ day, start_time, end_time }) => {
                const dayIndex = days.indexOf(day);
                const gridRowStart = Math.ceil(convertTimeToMinutes(start_time) / 5) + 7;  // Directly map minutes to grid rows, divided by 5 and add 6 for the empty cell
                const rowSpan = calculateRowSpan(start_time, end_time);
            
                const classColor = classColors[index % classColors.length]; // Assign a unique color to each class
            
                return (
                  <div
                    key={`${index}-${day}`}
                    className="class-card"
                    style={{
                      gridRow: `${gridRowStart} / span ${rowSpan}`, // Set the row span and start
                      gridColumn: `${dayIndex + 2}`, // Ensures it's placed in the correct day column
                      backgroundColor: classColor, // Assign unique color to the class
                    }}
                    onClick={() => handleClassClick(classData)} // Handle click event
                  >
                    <h4>{classData.class_code}</h4>
                    <p>{classData.instructor}</p>
                    <p>{start_time} - {end_time}</p>
                  </div>
                );
              });
            })
          ) : (
            <p></p>
          )}
        </div>
      </div>

      {/* Modal for showing detailed class info */}
      {selectedClass && (
      <div className="schedule-modal">
        <div className="schedule-modal-content">
          <h2>{selectedClass.class_code} - {selectedClass.course_topic}</h2>
          <p><strong>Instructor:</strong> {selectedClass.instructor}</p>
          <p><strong>Class Number:</strong> {selectedClass.class_number}</p>
          <p><strong>Enrollment Status:</strong> {selectedClass.status}</p>
          <p><strong>Location:</strong> {selectedClass.room}</p>
          
          <p><strong>Days and Times:</strong></p>
          {parseDaysTimes(selectedClass.days_times).map(({ day, start_time, end_time }, index) => (
            <p key={index}>
              {day}: {start_time} - {end_time}
            </p>
          ))}
          
          <p><strong>Meeting Dates:</strong> {selectedClass.meeting_dates}</p>
          <p><strong>Instruction Mode:</strong> {selectedClass.instruction_mode}</p>
          
          <button onClick={closeModal}>Close</button>
        </div>
      </div>
      )}
    </div>
  );
};

export default MySchedule;
