import React from 'react';
import { useNavigate } from 'react-router-dom';
import './about-us.css'; // Importing the CSS file for styling

function AboutUs() {
  const navigate = useNavigate();

  return (
    <div className="aboutus-container">
      {/* Navigation */}
      <header className="aboutus-header">
        <img
          src="logo192.png" // Replace with the actual path to your logo
          alt="Logo"
          className="aboutus-logo"
        />
        <nav className="aboutus-nav">
          <button
            className="aboutus-button"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
          <button
            className="aboutus-button aboutus-button-primary"
            onClick={() => navigate('/signup')}
          >
            Sign Up
          </button>
        </nav>
      </header>

      {/* Main Content */}
      <main className="aboutus-main">
        {/* Mission Statement */}
        <section className="aboutus-section">
          <h1 className="aboutus-title">Our Mission</h1>
          <p className="aboutus-text">
            Our mission is to empower CUNY students by streamlining the course registration process.
            We aim to ensure you never miss out on the classes you need for your academic success.
          </p>
        </section>

        {/* Who We Are */}
        <section className="aboutus-section">
          <h2 className="aboutus-subtitle">Who We Are</h2>
          <p className="aboutus-text">
            We are a dedicated team committed to enhancing the student experience at CUNY.
            We understand the frustration of trying to enroll in full classes and the stress
            of constantly checking for openings.
          </p>
        </section>

        {/* What We Offer */}
        <section className="aboutus-section">
          <h2 className="aboutus-subtitle">What We Offer</h2>

          {/* Features */}
          <div className="aboutus-features">
            {/* Free Tier */}
            <div className="aboutus-feature">
              <h3 className="aboutus-featureTitle">Free Tier</h3>
              <p className="aboutus-text">
                Track up to 10 classes simultaneously. Receive instant email and text notifications when a class you're interested in becomes available.
              </p>
            </div>

            {/* Silver Tier */}
            <div className="aboutus-feature">
              <h3 className="aboutus-featureTitle">Silver Tier</h3>
              <p className="aboutus-text">
                Enjoy unlimited class tracking and automatic enrollment. As soon as a class opens up, we'll secure your spot without any action needed from you.
              </p>
            </div>

            {/* Custom Schedule Builder */}
            <div className="aboutus-feature">
              <h3 className="aboutus-featureTitle">Custom Schedule Builder</h3>
              <p className="aboutus-text">
                Build your ideal schedule. If a desired class conflicts with your current schedule, our system will automatically adjust by dropping conflicting classes and enrolling you in your preferred ones as soon as they become available.
              </p>
            </div>
          </div>
        </section>

        {/* Stock Images */}
        <section className="aboutus-imageSection">
          <img
            src="student_in_class.png" // Corrected image path
            alt="Students collaborating"
            className="aboutus-stockImage"
          />
          <img
            src="university_campus.png" // Replace with actual image path
            alt="University campus"
            className="aboutus-stockImage"
          />
        </section>

        {/* Call to Action */}
        <section className="aboutus-ctaSection">
          <h2 className="aboutus-subtitle">Ready to Get Started?</h2>
          <p className="aboutus-text">
            Take control of your class schedule and never miss an opportunity to enroll in the classes you need.
          </p>
          <button
            className="aboutus-button aboutus-button-primary"
            onClick={() => navigate('/signup')}
          >
            Sign Up Now
          </button>
        </section>
      </main>

      {/* Footer */}
      <footer className="aboutus-footer">
        <p className="aboutus-footerText">
          &copy; {new Date().getFullYear()} CUNYCourseSniper. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default AboutUs;
