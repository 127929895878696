import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import logo from './logo192.png';
import { ThreeDots } from 'react-loader-spinner';
import './login.css'; 
import { ProfileContext } from './ProfileContext';



function Login() {
  const { fetchProfile } = useContext(ProfileContext); // Get fetchProfile from context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember Me"

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');  // Optionally load password
    
    if (savedEmail) {
      setEmail(savedEmail);
      setPassword(savedPassword || '');  // Optionally load the password
      setRememberMe(true);  // Set the checkbox to be checked if email was remembered
    }
  }, []);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage(false);
    setLoading(true);  // Show the spinner
    try {
      // console.log("attempting to login");
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://localhost:5050';
      const response = await axios.post(`${backendUrl}/login`, 
        { email, password }, 
        { withCredentials: true }
      );
      // console.log("passed the axios post this is the response " + response.data);
      // console.log(response.data);
      setMessage(response.data.message);

      if (response.data.message.toLowerCase() === 'login successful') {
        // console.log(response.data)
        // console.log("Correct login message received");
        // Save email and password to localStorage if "Remember Me" is checked
        sessionStorage.setItem('email', email);
        if (rememberMe) {
          localStorage.setItem('email', email);
          // localStorage.setItem('password', password);  // Optionally store password (not recommended for security reasons)
        } else {
          // If not remembering, remove it from localStorage
          localStorage.removeItem('email');
          // localStorage.removeItem('password');
        }
        // console.log('response.data.token: ' + response.data.token);
        if (response.data.token) {
          sessionStorage.setItem('jwtToken', response.data.token); // Store the JWT token
        }
        if (response.data.membership_level) {
          sessionStorage.setItem('membership_level', response.data.membership_level); // Store the membership level
          // console.log('membership_level: ' + response.data.membership_level);
        }

        // console.log('navigating to page');
        setIsAuthenticated(true);

        await fetchProfile();
        if (response.data.first_login === 1) {
          // Redirect to a welcome page or profile page
          // console.log('NAVIGATING TO PROFILE');
          navigate('/profile');
        } else {
          // Normal login flow
          // console.log('NAVIGATING TO HOMEPAGE');
          navigate('/homepage');
        } 
      }
      } catch (error) {
          // Handle error response
          if (error.response && error.response.status === 400) {
              // Check if there's already a message before setting a new one
              if (!message) {
                  setMessage(error.response.data.error || "Your login information doesn’t match our records. Please try again or reset your password.");
              }
          } else {
              // Handle other types of errors (e.g., server errors)
              if (!message) {
                  setMessage('An unexpected error occurred. Please try again later.');
              }
          }
      } finally {
          setLoading(false);  // Hide the spinner
      }    
  };
  
  return (
    <div className="login-page-container">
      <div className="login-page-box">
        <img src={logo} alt="CUNY Course Sniper Logo" className="login-page-logo" /> {/* Logo added here */}
        <form onSubmit={handleLogin}>
          <div className="login-page-inputGroup1">
            <label className="login-page-label">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-page-input"
              autoComplete="email"  // Enable autocomplete for email
            />
          </div>
          <div className="login-page-inputGroup2">
            <label className="login-page-label">Password:</label>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-page-input"
              autoComplete="current-password"  // Enable autocomplete for password
            />
          </div>
          <div className="login-page-checkboxContainer">
            <label className="login-page-checkboxLabel">
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                className="login-page-checkbox"
              />
              Show Password
            </label>

            <label className="login-page-checkboxLabel">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                className="login-page-checkbox"
              />
              Remember Me
            </label>
          </div>
          <button type="submit" className="login-page-button" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
          {loading && (
            <div className="login-page-loaderContainer">
              <ThreeDots color="#007bff" height={40} width={40} />
            </div>
          )}
        </form>
        <p 
          className="link" 
          style={{ 
            textAlign: 'center', 
            marginTop: '10px' // Add some margin if needed 
          }}
        >
          <Link to="/signup">Don't have an account? Sign up</Link>
        </p>
        <p
          className="link" 
          style={{ 
            textAlign: 'center', 
            marginTop: '10px' // Add some margin if needed 
          }}
        >
          <Link to="/forgot-password">Forgot your password? Click Here</Link>
        </p>
        {message && (
          <p 
            className={
              message === 'Login Successful'
                ? "login-page-successMessage" 
                : "login-page-errorMessage"
            }
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
}  

export default Login;
