import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  const checkAuth = async () => {
    try {
      console.log("Checking authentication");
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5050';
      const response = await axios.get(`${backendUrl}/check-login`, { withCredentials: true });
      console.log("got a reponse");
      if (response.data.login === 'True') {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate('/login');  // Redirect to login page if not authenticated
      }
    } catch (error) {
      setIsAuthenticated(false);
      console.log("In the catch")
      if (error.response && error.response.status === 401) {
        setIsAuthenticated(false);
        navigate('/login');  // Redirect to login if not authenticated
        console.log("Redirected to Login")
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []); 

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, loading, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
